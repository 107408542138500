import React, { useState } from 'react';
import { AlertTriangle } from 'lucide-react';

const MainDashboard = ({ renderSection }) => {
  const [activeSection, setActiveSection] = useState('overview');

  // Navigation sections
  const sections = [
    { id: 'overview', name: 'Overview' },
    { id: 'customer', name: 'Customer Impact' },
    { id: 'platforms', name: 'Platform Coverage' },
    { id: 'products', name: 'Product Analysis' },
    { id: 'detection', name: 'Detection Evolution' },
    { id: 'campaigns', name: 'Campaign Analysis' }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-amber-600 text-white px-4 py-2">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <AlertTriangle className="h-5 w-5" />
            <span className="font-medium">TLP:AMBER+STRICT</span>
          </div>
          <div className="text-sm">
            Recipients may only share with members of their own organization who need to know to act on the information.
          </div>
        </div>
      </div>

      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Header */}
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <div className="mb-4">
            <h1 className="text-2xl font-bold">Threat Findings - Q3 2024</h1>
            <p className="text-gray-600">Interactive analysis of security findings and trends from the Datadog Security Research Team</p>
          </div>
        </div>

        {/* Navigation */}
        <div className="mt-6 bg-white rounded-lg shadow-sm">
          <div className="border-b">
            <nav className="flex space-x-8 px-6" aria-label="Tabs">
              {sections.map((section) => (
                <button
                  key={section.id}
                  onClick={() => setActiveSection(section.id)}
                  className={`
                    flex items-center px-3 py-4 text-sm font-medium border-b-2 
                    ${activeSection === section.id
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                  `}
                >
                  {section.name}
                </button>
              ))}
            </nav>
          </div>

          {/* Content Area */}
          <div className="p-6">
            {renderSection(activeSection)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;