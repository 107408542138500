import { Navigate, Outlet } from 'react-router-dom';
import authService from './authService';

const PrivateRoute = () => {
  const isAuthenticated = authService.isAuthenticated();
  
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;