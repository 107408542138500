import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import MainDashboard from './MainDashboard';
import PrivateRoute from './PrivateRoute';
import OverviewSection from './OverviewSection';
import CustomerImpactSection from './CustomerImpactSection';
import PlatformCoverageSection from './PlatformCoverageSection';
import ProductAnalysisSection from './ProductAnalysisSection';
import DetectionEvolutionSection from './DetectionEvolutionSection';
import CampaignAnalysisSection from './CampaignAnalysisSection';

const App = () => {
  const renderSection = (section) => {
    switch (section) {
      case 'overview':
        return <OverviewSection />;
      case 'customer':
          return <CustomerImpactSection />;
      case 'platforms':
        return <PlatformCoverageSection />;
      case 'products':
        return <ProductAnalysisSection />;
      case 'detection':
        return <DetectionEvolutionSection />;
      case 'campaigns':
        return <CampaignAnalysisSection />;
      default:
        return <OverviewSection />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        
        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route 
            path="/dashboard" 
            element={<MainDashboard renderSection={renderSection} />} 
          />
        </Route>

        {/* Redirect root to dashboard */}
        <Route 
          path="/" 
          element={<Navigate to="/dashboard" replace />} 
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;