import React from 'react';
import { BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Shield, Search, Zap, Activity } from 'lucide-react';

const DetectionEvolutionSection = ({ timeframe, platform, severity, product }) => {
  // Detection Method Evolution Data
  const detectionTrends = [
    { quarter: 'Q1', automated: 7, manual: 3, total: 10, automatedPercentage: 70 },
    { quarter: 'Q2', automated: 29, manual: 20, total: 49, automatedPercentage: 59 },
    { quarter: 'Q3', automated: 79, manual: 9, total: 88, automatedPercentage: 89.8 }
  ];

  // Lead Signals Data
  const leadSignals = [
    { 
      signal: 'AWS SES Discovery',
      count: 18,
      critical: true,
      platform: 'AWS',
      automated: true
    },
    { 
      signal: 'M365 BEC Rules',
      count: 17,
      critical: true,
      platform: 'M365',
      automated: true
    },
    { 
      signal: 'AWS SNS Enumeration',
      count: 9,
      critical: true,
      platform: 'AWS',
      automated: true
    },
    { 
      signal: 'M365 OAuth Consent',
      count: 7,
      critical: true,
      platform: 'M365',
      automated: true
    },
    { 
      signal: 'Tor Client Activity',
      count: 5,
      critical: true,
      platform: 'Multiple',
      automated: true
    }
  ];

  // Detection Source Distribution
  const detectionSources = [
    { quarter: 'Q1', SIEM: 2, ASM: 3, CWS: 3, ThreatHunting: 2 },
    { quarter: 'Q2', SIEM: 26, ASM: 2, CWS: 1, ThreatHunting: 20 },
    { quarter: 'Q3', SIEM: 68, ASM: 16, CWS: 3, ThreatHunting: 0 }
  ];

  // Detection Quality Metrics - Temporarily Hidden
  // eslint-disable-next-line
  const qualityMetrics = [
    { quarter: 'Q1', criticalSignals: 7, totalSignals: 10, criticalPercentage: 70 },
    { quarter: 'Q2', criticalSignals: 21, totalSignals: 49, criticalPercentage: 42.9 },
    { quarter: 'Q3', criticalSignals: 79, totalSignals: 88, criticalPercentage: 89.8 }
  ];

  const COLORS = ['#2563eb', '#16a34a', '#dc2626', '#9333ea'];

  return (
    <div className="space-y-6">
      {/* Summary Metrics */}
      <div className="grid grid-cols-4 gap-4">
        <div className="p-4 bg-blue-50 rounded-lg">
          <Shield className="h-6 w-6 text-blue-600 mb-2" />
          <p className="text-2xl font-bold">89.8%</p>
          <p className="text-sm text-gray-600">Automation Rate</p>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <Search className="h-6 w-6 text-green-600 mb-2" />
          <p className="text-2xl font-bold">79</p>
          <p className="text-sm text-gray-600">Critical Findings</p>
        </div>
        <div className="p-4 bg-purple-50 rounded-lg">
          <Zap className="h-6 w-6 text-purple-600 mb-2" />
          <p className="text-2xl font-bold">-55%</p>
          <p className="text-sm text-gray-600">Manual Hunting</p>
        </div>
        <div className="p-4 bg-yellow-50 rounded-lg">
          <Activity className="h-6 w-6 text-yellow-600 mb-2" />
          <p className="text-2xl font-bold">Identity</p>
          <p className="text-sm text-gray-600">Primary Target</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Detection Source Maturity */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Detection Source Evolution</h3>
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={detectionSources}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quarter" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="SIEM" fill="#2563eb" />
                <Bar dataKey="ASM" fill="#16a34a" />
                <Bar dataKey="CWS" fill="#dc2626" />
                <Bar dataKey="ThreatHunting" fill="#9333ea" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Detection Method Evolution */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Detection Method Evolution</h3>
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={detectionTrends}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quarter" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="automated" stackId="a" fill="#2563eb" name="Automated Detection" />
                <Bar dataKey="manual" stackId="a" fill="#16a34a" name="Manual Hunting" />
                <Line
                  type="monotone"
                  dataKey="automatedPercentage"
                  stroke="#dc2626"
                  name="Automation %"
                  yAxisId={1}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Top Lead Signals */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Top 5 Lead Signal Categories</h3>
        <div className="h-72">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={leadSignals} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="signal" type="category" width={150} />
              <Tooltip />
              <Bar dataKey="count" name="Occurrences">
                {leadSignals.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Detection Insights */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Key Detection Insights</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className="p-4 border rounded-lg">
            <h4 className="font-semibold text-blue-600">Automation Success</h4>
            <p className="text-sm text-gray-600 mt-2">
              89.8% automated detection rate achieved in Q3, representing a significant improvement in efficiency
            </p>
          </div>
          <div className="p-4 border rounded-lg">
            <h4 className="font-semibold text-green-600">Manual Hunting Optimization</h4>
            <p className="text-sm text-gray-600 mt-2">
              55% reduction in manual hunting needs while maintaining high detection quality
            </p>
          </div>
          <div className="p-4 border rounded-lg">
            <h4 className="font-semibold text-purple-600">Detection Quality</h4>
            <p className="text-sm text-gray-600 mt-2">
              Increased critical finding ratio to 89.8%, showing improved detection accuracy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetectionEvolutionSection;