import React from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Shield, AlertTriangle, Target, Layers } from 'lucide-react';

const PlatformCoverageSection = ({ timeframe, platform, severity, product }) => {
  // Platform evolution data
  const platformTrends = [
    { quarter: 'Q1', AWS: 10, M365: 5, Application: 0, Azure: 0, Endpoint: 0 },
    { quarter: 'Q2', AWS: 39, M365: 5, Application: 2, Azure: 2, Endpoint: 1 },
    { quarter: 'Q3', AWS: 37, M365: 27, Application: 16, Azure: 4, Endpoint: 4 }
  ];

  // Platform metrics
  const platformMetrics = [
    {
      platform: 'AWS',
      findings: 37,
      critical: 31,
      missingProducts: 'ASM, CSM',
      attackTypes: ['Access Key', 'Control Plane']
    },
    {
      platform: 'M365',
      findings: 27,
      critical: 24,
      missingProducts: 'CSM',
      attackTypes: ['BEC', 'OAuth']
    },
    {
      platform: 'Application',
      findings: 16,
      critical: 14,
      missingProducts: 'CSM',
      attackTypes: ['Credential Stuffing', 'SQL Injection']
    },
    {
      platform: 'Azure',
      findings: 4,
      critical: 4,
      missingProducts: 'ASM',
      attackTypes: ['Identity', 'Control Plane']
    },
    {
      platform: 'Endpoint',
      findings: 4,
      critical: 4,
      missingProducts: 'SIEM',
      attackTypes: ['Process', 'Shell']
    }
  ];

  // Attack surface distribution
  const attackSurfaces = [
    { surface: 'Cloud Control Plane', count: 37 },
    { surface: 'SaaS Platform', count: 32 },
    { surface: 'Code/Runtime', count: 16 },
    { surface: 'Container/Runtime', count: 3 }
  ];

  // eslint-disable-next-line no-unused-vars
  const COLORS = ['#2563eb', '#16a34a', '#dc2626', '#9333ea', '#eab308'];

  return (
    <div className="space-y-6">
      {/* Summary Metrics */}
      <div className="grid grid-cols-4 gap-4">
        <div className="p-4 bg-blue-50 rounded-lg">
          <Shield className="h-6 w-6 text-blue-600 mb-2" />
          <p className="text-2xl font-bold">5</p>
          <p className="text-sm text-gray-600">Platforms Monitored</p>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <Target className="h-6 w-6 text-green-600 mb-2" />
          <p className="text-2xl font-bold">AWS</p>
          <p className="text-sm text-gray-600">Top Targeted Platform</p>
        </div>
        <div className="p-4 bg-red-50 rounded-lg">
          <AlertTriangle className="h-6 w-6 text-red-600 mb-2" />
          <p className="text-2xl font-bold">77</p>
          <p className="text-sm text-gray-600">Critical Findings</p>
        </div>
        <div className="p-4 bg-purple-50 rounded-lg">
          <Layers className="h-6 w-6 text-purple-600 mb-2" />
          <p className="text-2xl font-bold">4</p>
          <p className="text-sm text-gray-600">Attack Surfaces</p>
        </div>
      </div>

      {/* Platform Evolution Chart */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Platform Coverage Evolution</h3>
        <div className="h-72">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={platformTrends}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="quarter" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="AWS" stroke="#2563eb" strokeWidth={2} />
              <Line type="monotone" dataKey="M365" stroke="#16a34a" strokeWidth={2} />
              <Line type="monotone" dataKey="Application" stroke="#dc2626" strokeWidth={2} />
              <Line type="monotone" dataKey="Azure" stroke="#9333ea" strokeWidth={2} />
              <Line type="monotone" dataKey="Endpoint" stroke="#eab308" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Platform Details Grid */}
      <div className="grid grid-cols-5 gap-4">
        {platformMetrics.map((platform, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-blue-600 mb-2">{platform.platform}</h4>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-gray-600">Findings:</span>
                <span className="font-medium">{platform.findings}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-gray-600">Critical:</span>
                <span className="font-medium text-red-600">{platform.critical}</span>
              </div>
              <div className="text-sm text-gray-600">
                <p className="font-medium mt-2">Attack Types:</p>
                <ul className="list-disc list-inside">
                  {platform.attackTypes.map((type, idx) => (
                    <li key={idx} className="text-xs">{type}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Attack Surface Distribution */}
      <div className="grid grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Attack Surface Distribution</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={attackSurfaces} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="surface" type="category" width={150} />
                <Tooltip />
                <Bar dataKey="count" name="Number of Findings">
                  {attackSurfaces.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Key Findings Summary */}
        <div className="bg-white p-6 rounded-lg shadow space-y-4">
          <h3 className="text-lg font-semibold">Key Platform Insights</h3>
          <div className="space-y-4">
            <div className="p-3 bg-blue-50 rounded">
              <h4 className="font-medium text-blue-800">AWS Dominance</h4>
              <p className="text-sm text-gray-600">42% of all findings originate from the AWS control plane</p>
            </div>
            <div className="p-3 bg-green-50 rounded">
              <h4 className="font-medium text-green-800">M365 Growth</h4>
              <p className="text-sm text-gray-600">440% increase in M365 findings from Q2</p>
            </div>
            <div className="p-3 bg-purple-50 rounded">
              <h4 className="font-medium text-purple-800">Application Security</h4>
              <p className="text-sm text-gray-600">700% increase in application layer findings</p>
            </div>
          </div>
        </div>
        
      </div>
      {/* Platform Growth Analysis */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Intrusions by Platform Q2 - Q3</h3>
        <div className="space-y-4">
          {Object.keys(platformTrends[0])
            .filter(key => key !== 'quarter')
            .map((platform) => {
              const q2Value = platformTrends[1][platform];
              const q3Value = platformTrends[2][platform];
              const growthValue = q2Value === 0 
                ? 'N/A' 
                : Math.round(((q3Value - q2Value) / q2Value) * 100);
              const growth = growthValue === 'N/A' ? 'N/A' : `${growthValue}%`;
              const maxQ3 = Math.max(...Object.values(platformTrends[2]).filter(val => typeof val === 'number'));

              return (
                <div key={platform} className="flex items-center space-x-4">
                  <div className="w-24 font-medium">{platform}</div>
                  <div className="flex-1 h-4 bg-gray-100 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-blue-500"
                      style={{ width: `${(q3Value / maxQ3) * 100}%` }}
                    ></div>
                  </div>
                  <div className={`w-20 text-right text-sm font-medium ${
                    growth === 'N/A' ? 'text-gray-600' : 
                    growthValue < 0 ? 'text-red-600' : 'text-green-600'
                  }`}>
                    {growth}
                  </div>
                  <div className="w-32 text-right text-sm">
                    {q2Value} → {q3Value}
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
};

export default PlatformCoverageSection;