import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Shield, AlertTriangle, Target, Layers } from 'lucide-react';

const ProductAnalysisSection = ({ timeframe, platform, severity, product }) => {
  // Missing Products Evolution
  const missingProductsTrend = [
    { quarter: 'Q1', ASM: 2, CSM: 4, SIEM: 0, total: 6 },
    { quarter: 'Q2', ASM: 23, CSM: 16, SIEM: 8, total: 47 },
    { quarter: 'Q3', ASM: 36, CSM: 36, SIEM: 9, total: 81 }
  ];

  // Product Capability Gaps
  const capabilityGaps = [
    { capability: 'Detection Grouping', count: 11, impact: 'High', description: 'Need for better correlation of related events' },
    { capability: 'Notifications', count: 5, impact: 'Medium', description: 'Enhanced notification delivery and tracking' },
    { capability: 'Product Issue', count: 2, impact: 'Low', description: 'General product functionality improvements' },
    { capability: 'UX', count: 2, impact: 'Medium', description: 'User interface and experience enhancements' }
  ];

  // Impact Analysis by Attack Type - Temporarily Hidden
  // eslint-disable-next-line 
  const attackTypeImpact = [
    { 
      type: 'Compromised Access Key',
      withProducts: 92,
      withoutProducts: 45,
      missingProducts: 'ASM, CSM'
    },
    { 
      type: 'Business Email Compromise',
      withProducts: 88,
      withoutProducts: 40,
      missingProducts: 'CSM'
    },
    { 
      type: 'Credential Stuffing',
      withProducts: 85,
      withoutProducts: 35,
      missingProducts: 'SIEM'
    }
  ];
  
  const COLORS = ['#2563eb', '#16a34a', '#dc2626', '#9333ea'];

  return (
    <div className="space-y-6">
      {/* Summary Metrics */}
      <div className="grid grid-cols-4 gap-4">
        <div className="p-4 bg-blue-50 rounded-lg">
          <Shield className="h-6 w-6 text-blue-600 mb-2" />
          <p className="text-2xl font-bold">81</p>
          <p className="text-sm text-gray-600">Missing Product Instances</p>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <Target className="h-6 w-6 text-green-600 mb-2" />
          <p className="text-2xl font-bold">89.8%</p>
          <p className="text-sm text-gray-600">Automated Detection Findings</p>
        </div>
        <div className="p-4 bg-red-50 rounded-lg">
          <AlertTriangle className="h-6 w-6 text-red-600 mb-2" />
          <p className="text-2xl font-bold">72</p>
          <p className="text-sm text-gray-600">ASM+CSM Gaps in Coverage</p>
        </div>
        <div className="p-4 bg-purple-50 rounded-lg">
          <Layers className="h-6 w-6 text-purple-600 mb-2" />
          <p className="text-2xl font-bold">20</p>
          <p className="text-sm text-gray-600">Capability Gaps Identified</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Missing Products Evolution */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Missing Products Evolution</h3>
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={missingProductsTrend}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quarter" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="ASM" fill="#2563eb" name="ASM Missing" />
                <Bar dataKey="CSM" fill="#16a34a" name="CSM Missing" />
                <Bar dataKey="SIEM" fill="#dc2626" name="SIEM Missing" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Product Capability Gaps */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Product Capability Gaps</h3>
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={capabilityGaps} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="capability" type="category" width={150} />
                <Tooltip />
                <Bar dataKey="count" name="Instances">
                  {capabilityGaps.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Impact Analysis - Temporarily Hidden
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Attack Type Coverage Analysis</h3>
        <div className="grid grid-cols-3 gap-4">
          {attackTypeImpact.map((attack, index) => (
            <div key={index} className="p-4 border rounded-lg">
              <h4 className="font-semibold text-blue-600 mb-2">{attack.type}</h4>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-sm text-gray-600">With Products:</span>
                  <span className="font-medium text-green-600">{attack.withProducts}%</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm text-gray-600">Without Products:</span>
                  <span className="font-medium text-red-600">{attack.withoutProducts}%</span>
                </div>
                <div className="text-sm text-gray-600 mt-2">
                  <p className="font-medium">Missing Products:</p>
                  <p className="text-red-600">{attack.missingProducts}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      */}

      {/* Capability Gap Details */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Capability Gap Details</h3>
        <div className="grid grid-cols-2 gap-4">
          {capabilityGaps.map((gap, index) => (
            <div key={index} className="p-4 border rounded-lg">
              <div className="flex justify-between items-start mb-2">
                <h4 className="font-semibold text-blue-600">{gap.capability}</h4>
                <span className={`px-2 py-1 rounded text-xs font-medium 
                  ${gap.impact === 'High' ? 'bg-red-100 text-red-800' : 
                    gap.impact === 'Medium' ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-green-100 text-green-800'}`}>
                  {gap.impact}
                </span>
              </div>
              <p className="text-sm text-gray-600">{gap.description}</p>
              <div className="mt-2 flex items-center">
                <span className="text-sm font-medium text-gray-600">Instances:</span>
                <span className="ml-2 text-blue-600 font-medium">{gap.count}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductAnalysisSection;