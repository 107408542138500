const authService = {
  login: async (username, password) => {
    const response = await fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ username, password })
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('user', username);
    
    return { username };
  },

  logout: async () => {
    await fetch('/logout', {
      method: 'POST',
      credentials: 'include'
    });
    
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
  },

  isAuthenticated: () => {
    return localStorage.getItem('isAuthenticated') === 'true';
  }
};

export default authService;
