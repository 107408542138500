import React from 'react';
import { LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { Shield, AlertTriangle, Bell, TrendingUp } from 'lucide-react';

// eslint-disable-next-line no-unused-vars
const OverviewSection = () => {
  // Summary metrics data
  const summaryMetrics = [
    { 
      label: 'Total Findings', 
      value: 88, 
      change: '+80%', 
      icon: Shield,
      color: 'blue' 
    },
    { 
      label: 'Critical Signals',
      value: '89.8%',
      change: '+12%',
      icon: AlertTriangle,
      color: 'red'
    },
    { 
      label: 'Notifications',
      value: 27,
      change: '+80%',
      icon: Bell,
      color: 'green'
    },
    { 
      label: 'Avg Security ARR',
      value: '$36.5K',
      change: '+69%',
      icon: TrendingUp,
      color: 'purple'
    }
  ];

  // Quarterly growth data
  const quarterlyGrowth = [
    { quarter: 'Q1', findings: 11 },
    { quarter: 'Q2', findings: 49 },
    { quarter: 'Q3', findings: 88 }
  ];

  // Platform distribution data
  const platformDistribution = [
    { name: 'AWS', value: 37 },
    { name: 'M365', value: 27 },
    { name: 'Application', value: 16 },
    { name: 'Azure', value: 4 },
    { name: 'Endpoint', value: 4 }
  ];

  const COLORS = ['#2563eb', '#16a34a', '#dc2626', '#9333ea', '#eab308'];

  return (
    <div className="space-y-6">
      {/* Summary Metrics */}
      <div className="grid grid-cols-4 gap-4">
        {summaryMetrics.map((metric, index) => (
          <div key={index} className={`p-4 bg-${metric.color}-50 rounded-lg`}>
            <div className="flex items-center justify-between mb-2">
              <metric.icon className={`h-6 w-6 text-${metric.color}-600`} />
              <span className={`text-sm font-medium ${metric.change.startsWith('+') ? 'text-green-600' : 'text-red-600'}`}>
                {metric.change}
              </span>
            </div>
            <p className="text-2xl font-bold">{metric.value}</p>
            <p className="text-sm text-gray-600">{metric.label}</p>
          </div>
        ))}
      </div>

      {/* Quarterly Growth Chart */}
      <div className="grid grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Quarterly Growth</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={quarterlyGrowth}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quarter" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="findings" 
                  stroke="#2563eb" 
                  strokeWidth={2}
                  name="Total Findings" 
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Platform Distribution Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Detections by Platform - Q3</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={platformDistribution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#2563eb">
                  {platformDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Key Findings Summary */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Key Findings</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className="p-4 border rounded-lg">
            <h4 className="font-semibold text-blue-600">Detection Evolution</h4>
            <p className="text-sm text-gray-600 mt-2">
              89.8% automated detection rate achieved in Q3, showing significant improvement in efficiency
            </p>
          </div>
          <div className="p-4 border rounded-lg">
            <h4 className="font-semibold text-green-600">Platform Coverage</h4>
            <p className="text-sm text-gray-600 mt-2">
              Expanded coverage across AWS, M365, and Application platforms
            </p>
          </div>
          <div className="p-4 border rounded-lg">
            <h4 className="font-semibold text-purple-600">Customer Impact</h4>
            <p className="text-sm text-gray-600 mt-2">
              27 customer notifications sent with 29.6% response rate
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewSection;