import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const CampaignAnalysisSection = () => {
  // Major campaigns identified
  const campaigns = [
    {
      name: "Indonesian SNS Enumeration Campaign",
      ip: "36.70.225[.]99",
      attacks: 4,
      type: "AWS SNS Enumeration",
      details: "Multiple SNS enumeration attempts across different customers using identical Boto3 user agent",
      platforms: ["AWS"],
      techniques: ["SNS Enumeration", "Control Plane"]
    },
    {
      name: "Indonesian SES Discovery Campaign",
      ip: "36.81.25[.]43",
      attacks: 3,
      type: "AWS SES Discovery",
      details: "Consistent pattern of GetCallerIdentity followed by denied CreateCluster and GetAccount attempts",
      platforms: ["AWS"],
      techniques: ["SES Discovery", "Caller Identity"]
    },
    {
      name: "Distributed Credential Stuffing Campaign",
      ips: ["193.32.126[.]240", "146.70.184[.]117", "88.174.212[.]45"],
      attacks: 3,
      type: "Credential Stuffing",
      details: "Multi-day credential stuffing campaign using specific Chrome versions",
      platforms: ["Application"],
      techniques: ["Credential Stuffing", "Multiple User Agents"]
    }
  ];

  const attackDistribution = [
    { type: "AWS SNS", count: 9 },
    { type: "AWS SES", count: 18 },
    { type: "Credential Stuffing", count: 7 },
    { type: "BEC", count: 17 },
    { type: "Other", count: 37 }
  ].sort((a, b) => a.count - b.count);

  const COLORS = ['#2563eb', '#16a34a', '#dc2626', '#9333ea', '#eab308'];

  return (
    <div className="space-y-6">
      {/* Campaign Cards */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Coordinated Adversarial Campaigns Targeting Multiple Customers</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {campaigns.map((campaign) => (
            <div key={campaign.name} className="p-4 border rounded-lg">
              <h4 className="font-semibold text-blue-600 mb-2">{campaign.name}</h4>
              <div className="space-y-2">
                <div className="text-sm">
                  <span className="font-medium">Customers Targeted:</span> {campaign.attacks}
                </div>
                <div className="text-sm">
                  <span className="font-medium">Type:</span> {campaign.type}
                </div>
                <div className="text-sm">
                  <span className="font-medium">IP(s):</span> {campaign.ip || campaign.ips.join(", ")}
                </div>
                <div className="text-sm mt-2">
                  <span className="font-medium">Techniques:</span>
                  <div className="flex flex-wrap gap-1 mt-1">
                    {campaign.techniques.map((tech) => (
                      <span key={tech} className="px-2 py-1 text-xs bg-blue-50 text-blue-600 rounded-full">
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Attack Distribution Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Campaign Type Distribution</h3>
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={attackDistribution} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="type" type="category" width={100} />
                <Tooltip />
                <Bar dataKey="count">
                  {attackDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Campaign Statistics */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Campaign Statistics</h3>
          <div className="space-y-4">
            <div className="p-4 bg-blue-50 rounded-lg">
              <div className="text-sm text-blue-600">Total Coordinated Campaigns</div>
              <div className="text-2xl font-bold text-blue-900">3</div>
              <div className="text-xs text-blue-500">With repeated IP patterns</div>
            </div>
            <div className="p-4 bg-green-50 rounded-lg">
              <div className="text-sm text-green-600">Total Campaign Attacks</div>
              <div className="text-2xl font-bold text-green-900">10</div>
              <div className="text-xs text-green-500">Across identified campaigns</div>
            </div>
            <div className="p-4 bg-purple-50 rounded-lg">
              <div className="text-sm text-purple-600">Unique Attacking  IPs</div>
              <div className="text-2xl font-bold text-purple-900">5</div>
              <div className="text-xs text-purple-500">In coordinated campaigns</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignAnalysisSection; 