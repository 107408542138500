import React from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Users, Bell, Clock, CheckCircle2 } from 'lucide-react';

const CustomerImpactSection = ({ timeframe, platform, severity, product }) => {
  // Customer Engagement Trends
  const engagementTrends = [
    { quarter: 'Q1', notifications: 5, responses: 1, criticalFindings: 7, responseRate: 20 },
    { quarter: 'Q2', notifications: 15, responses: 13, criticalFindings: 21, responseRate: 86.7 },
    { quarter: 'Q3', notifications: 27, responses: 8, criticalFindings: 79, responseRate: 29.6 }
  ];

  // Response Actions
  const responseActions = [
    { 
      action: "Immediate Action",
      count: 3,
      examples: [
        "triaged and took immediate action to lock access",
        "investigated this and changed the user's password",
        "key has been disabled and will be rotated"
      ]
    },
    { 
      action: "Investigation Initiated",
      count: 3,
      examples: [
        "doing some additional investigation now",
        "following up today to track down root cause",
        "following up with these users"
      ]
    },
    { 
      action: "Internal Escalation",
      count: 2,
      examples: [
        "Thanks for the notification! I'll sort it out",
        "moving this internally"
      ]
    }
  ];

  // Security Controls Validated
  const controlsValidated = [
    {
      control: "MFA",
      context: "MFA saved us in this case",
      count: 3
    },
    {
      control: "Access Management",
      context: "key has been disabled and will be rotated",
      count: 5
    },
    {
      control: "Rule Detection",
      context: "Via the rule set and then the notice from you",
      count: 4
    }
  ];

  // Notification Process Evolution
  const processEvolution = [
    { 
      phase: "Initial Process",
      description: "CSM-driven notifications",
      limitations: ["Limited coverage", "Indirect communication", "Delayed responses"],
      rate: 20
    },
    {
      phase: "Current Process",
      description: "Direct security contacts",
      improvements: ["Broader coverage", "Direct communication", "Faster response"],
      rate: 29.6
    },
    {
      phase: "Future State (Goal)",
      description: "In-product notifications",
      expectedImprovements: ["Universal coverage", "Immediate delivery", "Integrated response"],
      targetRate: 75 
    }
  ];

  const arrMetrics = [
    { category: 'Enterprise', total: 2500000, security: 750000 },
    { category: 'Mid-Market', total: 1500000, security: 450000 },
    { category: 'SMB', total: 800000, security: 160000 }
  ];

  const FinancialMetrics = [
    {
      category: 'Average',
      total: 335720,
      security: 36521,
      ratio: 12.51
    },
    {
      category: 'Median',
      total: 159193,
      security: 8651,
      ratio: 10.98
    } 
  ];

  const COLORS = ['#2563eb', '#16a34a', '#dc2626', '#9333ea'];

  return (
    <div className="space-y-6">
      {/* Summary Metrics */}
      <div className="grid grid-cols-4 gap-4">
        <div className="p-4 bg-blue-50 rounded-lg">
          <Bell className="h-6 w-6 text-blue-600 mb-2" />
          <p className="text-2xl font-bold">27</p>
          <p className="text-sm text-gray-600">Notifications Sent</p>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <Users className="h-6 w-6 text-green-600 mb-2" />
          <p className="text-2xl font-bold">29.6%</p>
          <p className="text-sm text-gray-600">Response Rate</p>
        </div>
        <div className="p-4 bg-purple-50 rounded-lg">
          <Clock className="h-6 w-6 text-purple-600 mb-2" />
          <p className="text-2xl font-bold">8</p>
          <p className="text-sm text-gray-600">Actions Taken Following Notification</p>
        </div>
        <div className="p-4 bg-yellow-50 rounded-lg">
          <CheckCircle2 className="h-6 w-6 text-yellow-600 mb-2" />
          <p className="text-2xl font-bold">3</p>
          <p className="text-sm text-gray-600">Controls Validated</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Customer Engagement Trend */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Customer Engagement Evolution</h3>
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={engagementTrends}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quarter" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="notifications" 
                  stroke="#2563eb" 
                  strokeWidth={2}
                  name="Notifications" 
                />
                <Line 
                  type="monotone" 
                  dataKey="responses" 
                  stroke="#16a34a" 
                  strokeWidth={2}
                  name="Responses" 
                />
                <Line 
                  type="monotone" 
                  dataKey="criticalFindings" 
                  stroke="#dc2626" 
                  strokeWidth={2}
                  name="Critical Findings" 
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Response Actions */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4">Response Actions Distribution</h3>
            <div className="h-72">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={responseActions} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="action" type="category" width={150} />
                  <Tooltip />
                  <Bar dataKey="count" name="Number of Actions">
                    {responseActions.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* Add new Security Controls chart */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Security Controls Validated Through Customer Engagement</h3>
        <div className="grid grid-cols-[2fr,1fr] gap-6">
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={controlsValidated}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="control" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" name="Number of Validations">
                  {controlsValidated.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="space-y-4">
            {controlsValidated.map((control, index) => (
              <div key={index} className="p-3 bg-gray-50 rounded-lg">
                <h4 className="font-semibold text-blue-600 mb-2">{control.control}</h4>
                <p className="text-sm text-gray-600 italic">"{control.context}"</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Process Evolution */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Notification Process Evolution</h3>
        <div className="grid grid-cols-3 gap-4">
          {processEvolution.map((phase, index) => (
            <div key={index} className="p-4 border rounded-lg">
              <h4 className="font-semibold text-blue-600 mb-2">{phase.phase}</h4>
              <p className="text-sm text-gray-600 mb-2">{phase.description}</p>
              
              {phase.limitations && (
                <div className="mt-2">
                  <p className="text-sm font-medium text-red-600">Limitations:</p>
                  <ul className="text-sm text-gray-600 list-disc list-inside">
                    {phase.limitations.map((limit, idx) => (
                      <li key={idx}>{limit}</li>
                    ))}
                  </ul>
                </div>
              )}
              
              {phase.improvements && (
                <div className="mt-2">
                  <p className="text-sm font-medium text-green-600">Improvements:</p>
                  <ul className="text-sm text-gray-600 list-disc list-inside">
                    {phase.improvements.map((improvement, idx) => (
                      <li key={idx}>{improvement}</li>
                    ))}
                  </ul>
                </div>
              )}

              {phase.expectedImprovements && (
                <div className="mt-2">
                  <p className="text-sm font-medium text-purple-600">Expected Improvements:</p>
                  <ul className="text-sm text-gray-600 list-disc list-inside">
                    {phase.expectedImprovements.map((improvement, idx) => (
                      <li key={idx}>{improvement}</li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="mt-3 pt-3 border-t">
                <span className="text-sm font-medium">Response Rate: </span>
                <span className="text-lg font-bold text-blue-600">
                  {phase.rate || phase.targetRate}%
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Representative Feedback */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Actionable Customer Responses</h3>
        <div className="grid grid-cols-3 gap-4">
          {responseActions.map((action, index) => (
            <div key={index} className="p-4 border rounded-lg">
              <h4 className="font-semibold text-blue-600 mb-2">{action.action}</h4>
              <div className="space-y-2">
                {action.examples.map((example, idx) => (
                  <div key={idx} className="p-2 bg-gray-50 rounded">
                    <p className="text-sm text-gray-600 italic">"{example}"</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Financial Metrics Side by Side */}
      <div className="grid grid-cols-2 gap-6">
        {/* Financial Metrics */}
        <div className="bg-white p-6 rounded-lg shadow space-y-4">
          <h3 className="text-lg font-semibold">Q3 Annual Recurring Revenue (ARR) Metrics</h3>
          <div className="space-y-4">
            <div className="p-3 bg-blue-50 rounded">
              <h4 className="font-medium text-blue-800">Average Security Spend % of Total Ratio</h4>
              <p className="text-3xl font-bold text-blue-600">12.51%</p>
            </div>
            <div className="p-3 bg-green-50 rounded">
              <h4 className="font-medium text-green-800">Average Security ARR</h4>
              <p className="text-3xl font-bold text-green-600">$36,521</p>
            </div>
            <div className="p-3 bg-purple-50 rounded">
              <h4 className="font-medium text-purple-800">Median Security ARR</h4>
              <p className="text-3xl font-bold text-purple-600">$8,651</p>
            </div>
          </div>
        </div>

        {/* Q3 ARR Distribution */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Q3 Annual Recurring Revenue (ARR) Distribution</h3>
          <div className="h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={FinancialMetrics}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis tickFormatter={(value) => `${(value/1000000).toFixed(1)}M`} />
                <Tooltip 
                  formatter={(value) => `$${value.toLocaleString()}`}
                  labelStyle={{ color: '#374151' }}
                  contentStyle={{ 
                    backgroundColor: 'white',
                    border: '1px solid #e5e7eb',
                    borderRadius: '0.375rem'
                  }}
                />
                <Legend />
                <Bar 
                  dataKey="total" 
                  name="Total ARR" 
                  fill="#2563eb"
                  radius={[4, 4, 0, 0]} 
                />
                <Bar 
                  dataKey="security" 
                  name="Security ARR" 
                  fill="#16a34a"
                  radius={[4, 4, 0, 0]} 
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerImpactSection;